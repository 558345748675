import React, { useContext, useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import withPrivateRoute from '../../hocs/withPrivateRoute';
import { pricesStoreContext } from '../../stores/PricesStore';
import { Roles } from '../../utils/constants';
import Card from '../CardPrice/Card';

const PricesPacks: React.FC = () => {
	const { fetchPrices, prices } = useContext(pricesStoreContext);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		fetchPrices().finally(() => {
			setIsLoading(false);
		});
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<div className="flex flex-wrap gap-4">
			{prices.map((cardData, index) => (
				<Card
					key={index}
					cardData={cardData}
					buyPathRedirect={`/user/payment?priceId=${cardData.id}&packName=${cardData.title}`}
				/>
			))}
		</div>
	);
};

export default withPrivateRoute(PricesPacks, [Roles.admin, Roles.user]);
