import { Statistic } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { userStoreContext } from '../../stores/UserStore';

const PlayStatistics = () => {
	const { fetchProfileInfo, profile } = useContext(userStoreContext);

	useEffect(() => {
		fetchProfileInfo();
	}, []);

	return (
		<div className="flex flex-wrap gap-6">
			<div className="bg-blue-100  rounded-sm px-4 py-2">
				<Statistic title="Free requests" value={profile?.freeTipsLeft} />
			</div>

			<div className="bg-blue-100  rounded-sm px-4 py-2">
				<Statistic title="Left" value={profile?.paidTipsLeft} />
			</div>
			<div className="bg-blue-100  rounded-sm px-4 py-2">
				<Statistic title="Played today" value={profile?.playedForToday} />
			</div>
			<div className="bg-blue-100  rounded-sm px-4 py-2">
				<Statistic title="Played month" value={profile?.playedForMonth} />
			</div>
		</div>
	);
};

export default observer(PlayStatistics);
