import { Space, Typography } from 'antd';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import PlayAndPacksStatistics from '../../components/PlayAndPacksStatistics/PlayAndPacksStatistics';
import PricesPacks from '../../components/PricesPacks/PricesPacks';
import withPrivateRoute from '../../hocs/withPrivateRoute';
import { userStoreContext } from '../../stores/UserStore';
import { Roles } from '../../utils/constants';

const { Title } = Typography;

const Profile: React.FC = () => {
	const { user } = useContext(userStoreContext);
	return (
		<>
			<Helmet>
				<title>Profile info</title>
			</Helmet>

			<div className="page">
				<h1 className="page__title">
					<span className="mr-2">Profile</span>
					<span className="bg-blue-100  p-2 px-4 font-normal rounded-sm text-gray-500 text-sm">
						{user?.email}
					</span>
				</h1>
				<PlayAndPacksStatistics />
				<Space style={{ margin: '16px 0' }}>
					<Title level={4}>Available hint packages</Title>
				</Space>
				<PricesPacks />
			</div>
		</>
	);
};

export default withPrivateRoute(Profile, [Roles.admin, Roles.user]);
